import React, { useCallback, useEffect } from 'react';

import Router from 'next/router';

import { Form, Input, Button } from 'antd';

import analytics from '@repo/analytics';
import { hasCmsAccess, login } from 'apiClient';
import { Layout } from 'features/ui/Layout';
import { useProofOfWork } from 'hooks/useProofOfWork';
import { ApiDefaultResponseBody } from 'types/ApiResponse';
import { Account, UserWithToken } from 'types/user';
import { setAccessCookies } from 'utils/cookies';

type Props = {
  account?: Account;
};

const LoginPage: React.FC<Props> = () => {
  const { challenge, fetchChallenge } = useProofOfWork();

  useEffect(() => {
    if (challenge.isEnabled && !challenge.nonce) {
      fetchChallenge();
    }
  }, [challenge, fetchChallenge]);

  const onFinish = useCallback(
    async ({ email, password }) => {
      const loginResponse = await login(email, password, challenge);
      if ((loginResponse as ApiDefaultResponseBody).error) {
        Router.push('/restricted');
        return;
      }
      const auth = loginResponse as UserWithToken;
      if (!hasCmsAccess(auth)) {
        Router.push('/restricted');
        return;
      }
      const accessToken: string = auth.accessToken;
      setAccessCookies(accessToken);

      // Identify user in analytics
      analytics.identify(auth.id, {
        email: auth.email,
        name: auth.name,
        id: auth.id,
      });

      Router.push('/');
    },
    [challenge]
  );

  return (
    <Layout>
      <main>
        <Form
          initialValues={{ remember: true }}
          labelCol={{ span: 8 }}
          name="basic"
          wrapperCol={{ span: 16 }}
          onFinish={onFinish}
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button
              disabled={challenge.isEnabled && challenge.solution === undefined}
              htmlType="submit"
              type="primary"
            >
              Log in
            </Button>
          </Form.Item>
        </Form>
      </main>
      <style jsx>{`
        main {
          display: flex;
          justify-content: center;
          align-items: center;
          min-height: 360px;
        }
        main form {
          width: 480px;
        }
      `}</style>
    </Layout>
  );
};

export default LoginPage;
